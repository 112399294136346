import { createStore, createLogger } from 'vuex'
import contents from './modules/contents'


const debug = false;//process.env.NODE_ENV !== 'production'

export default createStore({
    modules: {
        contents,
    },
    strict: debug,
    plugins: debug ? [createLogger()] : []
})