<template>
  <router-view/>
</template>

<script>
import WOW from "wow.js";
document.www=window;
export default {
  name: 'app',
  computed: {},
  mounted() {
    new WOW().init();
    this.$store.dispatch('contents/getWorkbooks')
        .then(() => {
        });

  }
}
</script>

