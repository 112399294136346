import axios from "axios";

// initial state
// shape: [{ id, quantity }]
const state = () => ({
    workbooks: {},
    categories: [
        {
            name: 'Cinema',
            category: 'cinema',
            image: require('../../assets/img/icons/mdb/cinema.svg')
        },
        {
            name: 'Custo de Mídia',
            category: 'custo_midia',
            image: require('../../assets/img/icons/mdb/custo_midia.svg')
        },
        {
            name: 'Infográficos (2022)',
            category: 'infograficos',
            image: require('../../assets/img/icons/mdb/infograficos.svg')
        },
        {
            name: 'Infográficos (2023)',
            category: 'infograficos2023',
            image: require('../../assets/img/icons/mdb/infograficos.svg')
        },
        {
            name: 'Jornal',
            category: 'jornal',
            image: require('../../assets/img/icons/mdb/journal.svg')
        },
        {
            name: 'Kantar Ibope Links',
            category: 'kantar_ibope',
            image: require('../../assets/img/icons/mdb/kantar_ibope.svg')
        },
        {
            name: 'Mercado e Demografia',
            category: 'mercado_demografia',
            image: require('../../assets/img/icons/mdb/demografia.svg')
        },
        {
            name: 'Mídia Digital',
            category: 'midia_digital',
            image: require('../../assets/img/icons/mdb/midia_digital.svg')
        },
        {
            name: 'Mídia Out-of-Home',
            category: 'midia_ooh',
            image: require('../../assets/img/icons/mdb/ooh.svg')
        },
        // {
        //     name: 'Pesquisa de Mídia',
        //     category: 'pesquisa_midia',
        //     image: require('../../assets/img/icons/mdb/research.svg')
        // },
        {
            name: 'Rádio',
            category: 'radio',
            image: require('../../assets/img/icons/mdb/radio.svg')
        },
        {
            name: 'Revista',
            category: 'revista',
            image: require('../../assets/img/icons/mdb/revista.svg')
        },
        {
            name: 'TV Aberta',
            category: 'tv_aberta',
            image: require('../../assets/img/icons/mdb/tv.svg')
        },
        {
            name: 'TV Assinatura',
            category: 'tv_assinatura',
            image: require('../../assets/img/icons/mdb/paytv.svg')
        },
        {
            name: 'Descritivos',
            category: 'descritivos',
            image: require('../../assets/img/icons/mdb/research.svg')
        },

        {
            name: 'Todos',
            category: 'all',
            image: require('../../assets/img/icons/mdb/reset.svg')
        },


    ],
})

// getters
const getters = {
    workbooks:(state)=> {
        let res=[];
        for (const key in state.workbooks) {
            let workbook =  JSON.parse(JSON.stringify(state.workbooks[key]))  ;
            let category = state.categories.filter((item)=>item.category===workbook.project.category)
            workbook.workbooks.map((item) => {
                item.project = workbook.project
                item.category = category[0]
                res.push( JSON.parse(JSON.stringify(item)))
            });
        }
        return res;
    },

    categories: (state, getters) => {

        return state.categories;
    },
    getCategory: (state, getters) => (category) => {
            return state.categories.filter((item) => item.category === category)[0] ||

                {
                    name: 'Todos',
                    category: 'all',
                    image: require('../../assets/img/icons/mdb/reset.svg')
                };
    },
    getWorkbook: (state, getters) => (id) => {
        let res=[];
        for (const key in state.workbooks) {
            let workbook =  JSON.parse(JSON.stringify(state.workbooks[key]))  ;
            let category = state.categories.filter((item)=>item.category===workbook.project.category)
            workbook.workbooks.map((item) => {
                item.project = workbook.project
                item.category = category[0]
                res.push( JSON.parse(JSON.stringify(item)))
            });
        }
        return res.filter((item) => item.id === id)[0];
    }

}

// actions
const actions = {
    async getWorkbooks(context, payload) {
        let url = "https://app.self.bi/api/mdb/workbooks";

        return new Promise((onSuccess, onFail) => {

            const data = axios.get(url).then((response) => {
                context.commit("setContents", response.data);
                onSuccess(response.data);
            }).catch((error) => {
                onFail(error);
                console.log(error);
            });


        });


    },
    async getJwt(context, payload) {
        let url = "https://app.self.bi/api/mdb/jwt?id="+payload.id;

        return new Promise((onSuccess, onFail) => {
                const data = axios.get(url).then((response) => {
                    onSuccess(response.data);
                }).catch((error) => {
                    onFail(error);
                    console.log(error);
                });
        });
    }
    //22b7c617-fad6-4255-b68c-6881e6bbcc7f


}

// mutations
const mutations = {
    setContents(state, data) {
        Object.assign(state.workbooks, data);

    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
    modules: {}
}